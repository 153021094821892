<template>
  <div class="list">
    <div class="grey_box"></div>
    <div class="order_deatil">
      <div class="status_box">
        <div class="returnId">
          <div class="tiao_icon"></div>
          <div class="ml1">工单号：</div>
          <div>{{ detailsFrom.returnId }}</div>
        </div>
        <div class="status">
          {{
            detailsFrom.status == 0
              ? "待审核"
              : detailsFrom.status == -1
              ? "拒绝"
              : detailsFrom.status == 1
              ? "卖家待收货"
              : detailsFrom.status == 2
              ? "买家待收货"
              : detailsFrom.status == 3
              ? "已完成"
              : detailsFrom.status == 4
              ? "已取消"
              : detailsFrom.status == 5
              ? "检测中"
              : "未知状态"
          }}
        </div>
      </div>
      <div class="fenge"></div>
      <div class="tp_box">姓名：{{ detailsFrom.name }}</div>
      <div class="tp_box">联系方式：{{ detailsFrom.phone }}</div>
      <div class="tp_box">
        收货地址：{{
          detailsFrom.province + detailsFrom.city + detailsFrom.detailedAddress
        }}
      </div>
    </div>
    <div class="grey_box" style="height: 0.44rem"></div>
    <div class="shop_detail">
      <div class="title">商品详情</div>
      <div class="fenge"></div>
      <div class="goods_detail">
        <div>
          <div class="goods_parameter">
            产品类型：{{ detailsFrom.productTypeName }}
          </div>
          <div class="goods_parameter">
            产品型号：{{ detailsFrom.productCodeName }}
          </div>
          <div class="goods_parameter">
            颜色分类：{{ detailsFrom.colourName }}
          </div>
          <div class="goods_parameter">
            发票时间: {{ detailsFrom.createTime }}
          </div>
          <div class="goods_parameter">
            购买渠道:
            {{ detailsFrom.buyPathway == 7001 ? "线上网店" : "线下实体店" }}
          </div>
          <div class="goods_parameter">
            故障描述: {{ detailsFrom.faultDescribe }}
          </div>
          <div class="goods_parameter-img">
            <div>购买凭证:</div>
            <img v-for="(item, i) in detailsFrom.proof" :src="item" :key="i" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Uploader } from "vant";

import { getProductReturnById, wxPublicAuth } from "../api/home";
export default {
  name: "Detail",
  components: {
    "van-uploader": Uploader,
  },
  data() {
    return {
      detailsFrom: {},
    };
  },
  created() {
    this.geData();
    var url = window.location.href;
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
      var strs = url.split("?");
      var strs1 = strs[1].split("&");
      console.log("strs", strs);
      console.log("strs1", strs1);
      for (var i = 0; i < strs1.length; i++) {
        theRequest[strs1[i].split("=")[0]] = unescape(strs1[i].split("=")[1]);
      }
    }
    if (theRequest.token) {
      localStorage.setItem("token", theRequest.token);
      localStorage.setItem("openId", theRequest.openId);
    } else {
      if (localStorage.getItem("token") == null) {
        this.wxPublicAuth();
      }
    }
  },
  methods: {
    wxPublicAuth() {
      wxPublicAuth({
        page: "detail",
      })
        .then((res) => {
          console.log(res);

          window.location.href = res.data;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    geData() {
      getProductReturnById({
        returnId: this.$route.query.id,
      })
        .then((res) => {
          if (res.code == 200) {
            console.log(res.data, this.id);
            this.detailsFrom = res.data;
            this.detailsFrom.proof = JSON.parse(res.data.proof);
            console.log(this.detailsFrom);
          }
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>
<style scoped>
.min_title {
  color: #aaaaaa;
}
.img_upload {
  margin-left: 5rem;
}
.min_cont {
  color: #333333;
  margin-left: 5rem;
}
.min_content_img {
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.63rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 0.88rem;
  padding-left: 2rem;
}
.min_content_img img {
  width: 5rem;
  height: 5rem;
  margin-left: 0.3rem;
}
.min_content {
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.63rem;
  display: flex;
  flex-direction: row;
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: 0.88rem;
  padding-left: 2rem;
}
.goods_parameter {
  font-size: 0.75rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #aaaaaa;
  line-height: 1.03rem;
  text-align: left;
  margin-top: 0.78rem;
}
.goods_parameter-img {
  font-size: 0.75rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #aaaaaa;
  text-align: left;
  margin-top: 0.78rem;
}
.goods_parameter-img span {
  display: flex;
}
.goods_title {
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: 0.88rem;
  color: #333;
  text-align: left;
}
.goods_detail {
  width: 100%;
  height: 4.63rem;
  box-sizing: border-box;
  padding-left: 1.9rem;
  padding-right: 0.91rem;
  margin-top: 0.84rem;
  display: flex;
  justify-content: space-between;
}
.goods_detail img {
  width: 6.5rem;
  height: 4.63rem;
  border-radius: 0.34rem;
}
.title {
  width: 100;
  height: 1.56rem;
  line-height: 1.56rem;
  box-sizing: border-box;
  padding-left: 1.91rem;
  margin-top: 0.69rem;
  color: #333;
  font-size: 1.13rem;
  font-weight: 500;
}
.shop_detail {
  width: 100%;
  margin-top: 0.84rem;
  background-color: #fff;
}
.grey_box {
  width: 100%;
  height: 0.75rem;
  background-color: #f5f5f5;
}
.tp_box {
  width: 100%;
  height: 0.96rem;
  line-height: 0.96rem;
  display: flex;
  flex-direction: row;
  font-size: 0.88rem;
  color: #333;
  padding: 0 2.4rem;
  margin-top: 0.63rem;
  box-sizing: border-box;
}
.fenge {
  width: 100%;
  margin-top: 0.82rem;
  background-color: #f5f5f5;
  height: 0.06rem;
}
.status_box {
  display: flex;
  justify-content: space-between;
}
.order_deatil {
  width: 100%;
  background-color: #fff;
  /* height: 17.2rem; */

  box-sizing: border-box;
  padding: 0.9rem 0;
}
.status_box {
  color: #ff5b5b;
  line-height: 0.96rem;
  padding: 0 2.2rem;
}
.returnId {
  display: flex;
  justify-content: space-between;
  font-size: 0.96rem;
  color: #aaaaaa;
  height: 0.96rem;
  line-height: 0.96rem;
}
.ml1 {
  margin-left: 0.63rem;
}
.tiao_icon {
  width: 0.38rem;
  height: 0.96rem;
  background: #ff7775;
  border-radius: 0.31rem;
}
</style>
